
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiRoutes from "@/core/config/ApiRoutes";
import { hideModal } from "@/core/helpers/dom";
import { checkField, resetField, enableSubmitButton, disableSubmitButton } from "@/core/helpers/form";
import FieldError from "@/components/modals/shared/FieldError.vue";

export default defineComponent({
    name: "edit-employee-modal",
    emit: ["refreshData"],
    props: {
        employee: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
        FieldError,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editEmployeeModalRef = ref<null | HTMLElement>(null);

        let isUpdate = false as boolean;
        let errors: any = ref();
        let structures = ref([]);
        let positions = ref([]);
        let nationalities = ref([]);
        let analyticAccounts = ref([]);

        const employeeDefault = {
            id                   : null,
            code                 : "",
            last_name            : "",
            first_name           : "",
            gender               : "",
            civil_status         : "",
            id_number            : "",
            passport_number      : "",
            email                : "",
            phone                : "",
            address              : "",
            structure_type       : 1,
            structure_code       : "",
            position_id          : 0,
            nationality          : "",
            analytic_account_code: "",
            is_driver            : false,
            is_user              : false,
            is_head              : false,
        };
        const employeeData = ref<any>({});
        Object.assign(employeeData.value, employeeDefault);

        const validationSchema = Yup.object().shape({
            // code: Yup.string().required().label(translate("employeeCodeLabel")),
            // last_name: Yup.string().required().label(translate("employeeLastNameLabel")),
            // first_name: Yup.string().required().label(translate("employeeFirstNameLabel")),
            // gender: Yup.string().required().label(translate("employeeGenderLabel")),
            // civil_status: Yup.string().required().label(translate("employeeCivilStatusLabel")),
            // id_number: Yup.string().required().label(translate("employeeIdNumberLabel")),
            // passport_number: Yup.string().required().label(translate("employeePassportNumberLabel")),
            // email: Yup.string().email().required().label(translate("employeeEmailLabel")),
            // phone: Yup.string().required().label(translate("employeePhoneLabel")),
            // address: Yup.string().required().label(translate("employeeAddressLabel")),
            // structure_type: Yup.number().required().label(translate("employeeStructureTypeLabel")),
            // structure_code: Yup.string().required().label(translate("employeeStructureCodeLabel")),
            // position_id: Yup.number().required().label(translate("employeePositionIdLabel")),
            // nationality: Yup.string().required().label(translate("employeeNationalityLabel")),
            // analytic_account_code: Yup.string().nullable().label(translate("employeeAnalyticAccountCodeLabel")),
            // is_driver: Yup.boolean().label(translate("employeeIsDriverLabel")),
            // is_user: Yup.boolean().label(translate("employeeIsUserLabel")),
            // is_head: Yup.boolean().label(translate("employeeIsHeadLabel")),
        });

        const submit = (values, actions) => {
            errors.value = null;

            disableSubmitButton(submitButtonRef);

            if (isUpdate && props.employee.id) employeeData.value.id = props.employee.id;

            axios.post(ApiRoutes.employees.updateOrCreate, employeeData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton(submitButtonRef);

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editEmployeeModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton(submitButtonRef);

                    if (error.response.status == 422) {
                        errors.value = error.response.data.errors;
                    } else {

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                });
        };

        const getPositions = async () => {
            const response = await axios.get(ApiRoutes.employees.positions);

            positions.value = response.data;
        };

        const getAnalyticAccounts = async () => {
            const response = await axios.get(ApiRoutes.analyticAccounts.list);

            analyticAccounts.value = response.data;
        };

        const getNationalities = async () => {
            const response = await axios.get(ApiRoutes.countries.nationalities);

            nationalities.value = response.data;
        };

        const getStructures = async () => {
            const level = (employeeData.value) ? employeeData.value.structure_type : 1;
            const response = await axios.get(ApiRoutes.structures.list, { params: { level: level } });

            structures.value = response.data;
        };

        onMounted(() => {
            getStructures();
            getPositions();
            getNationalities();
            getAnalyticAccounts();

            const modal = document.getElementById('kt_modal_edit_employee');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(employeeData.value, employeeDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;
                errors.value = null;

                if (props.employee) {
                    isUpdate = true;

                    // Fill Intra fields
                    Object.keys(props.employee).forEach((key) => {
                        if (employeeDefault.hasOwnProperty(key)) {
                            if (typeof employeeDefault[key] == 'boolean') employeeData.value[key] = Boolean(props.employee[key]);
                            else employeeData.value[key] = props.employee[key];
                        }
                    });
                }
            });
        });

        return {
            translate,

            submitButtonRef,
            editEmployeeModalRef,

            validationSchema,

            errors,
            resetField,
            checkField,

            structures,
            positions,
            nationalities,
            analyticAccounts,
            getStructures,
            employeeData,

            submit,
        };
    },
});
