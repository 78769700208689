
import axios from "axios";
import { useI18n } from "vue-i18n/index";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

export default defineComponent({
    name: "search-employee-modal",
    emit: ["refreshData"],
    props: {
        params: null,
        getParams: Function
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let structures = ref([]);
        let positions = ref([]);
        let nationalities = ref([]);
        let analyticAccounts = ref([]);
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const searchEmployeeModalRef = ref<null | HTMLElement>(null);
        const initialValues = {
            code                 : "",
            last_name            : "",
            first_name           : "",
            gender               : "",
            civil_status         : "",
            id_number            : "",
            passport_number      : "",
            email                : "",
            phone                : "",
            address              : "",
            structure_type       : 1,
            structure_code       : "",
            position_id          : 0,
            nationality          : "",
            analytic_account_code: "",
            is_user              : false,
            is_driver            : false,
        };
        const queryParams = ref(props.params);
        Object.assign(queryParams.value, initialValues);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submit = (values, actions) => {

            if (!submitButtonRef.value) {
                return;
            }

            if (props.getParams) props.getParams(queryParams.value);

            hideModal(searchEmployeeModalRef.value);
        };

        const getPositions = async () => {
            const response = await axios.get(ApiRoutes.employees.positions);

            positions.value = response.data;
        };

        const getAnalyticAccounts = async () => {
            const response = await axios.get(ApiRoutes.analyticAccounts.list);

            analyticAccounts.value = response.data;
        };

        const getNationalities = async () => {
            const response = await axios.get(ApiRoutes.countries.nationalities);

            nationalities.value = response.data;
        };

        const getStructures = async () => {
            const level = (queryParams.value) ? queryParams.value.structure_type : 1;
            const response = await axios.get(ApiRoutes.structures.list, { params: { level: level } });

            structures.value = response.data;
        };

        onMounted(() => {
            getStructures();
            getPositions();
            getNationalities();
            getAnalyticAccounts();

            const modal = document.getElementById('kt_modal_search_employee');

            // if (modal) modal.addEventListener('hidden.bs.modal', function() {
            // });

            if (modal) modal.addEventListener('shown.bs.modal', function() {

                if (props.params) {
                    // Fill Intra fields
                    Object.keys(props.params).forEach((key) => {

                        if (initialValues.hasOwnProperty(key)) {
                            if (typeof initialValues[key] == 'boolean') queryParams.value[key] = Boolean(props.params[key]);
                            else queryParams.value[key] = props.params[key];
                        }
                    });
                }
            });
        });

        return {
            translate,
            structures,
            positions,
            nationalities,
            analyticAccounts,
            getStructures,
            queryParams,
            submit,
            submitButtonRef,
            searchEmployeeModalRef,
        };
    },
});
