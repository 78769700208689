
import { useI18n } from "vue-i18n/index";
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditEmployeeModal from "@/components/modals/referencials/employees/EditEmployeeModal.vue";
import SearchEmployeeModal from "@/components/modals/referencials/employees/SearchEmployeeModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-employees",
    props: {
        widgetClasses: String,
    },
    components: {
        EditEmployeeModal,
        SearchEmployeeModal,
    },
    setup() {
        const { t, te } = useI18n();
        let loading = ref(false);
        let activeEmployee = ref(null);
        const employees = ref([]);
        const queryParamsDefault = {
            code                 : "",
            last_name            : "",
            first_name           : "",
            gender               : "",
            civil_status         : "",
            id_number            : "",
            passport_number      : "",
            email                : "",
            phone                : "",
            address              : "",
            structure_type       : 1,
            structure_code       : "",
            position_id          : 0,
            nationality          : "",
            analytic_account_code: "",
            is_user              : 0,
            is_driver            : 0,
            is_head            : 0,
        };
        const queryParams = ref({});
        Object.assign(queryParams.value, queryParamsDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const queryParamsLabels = {
            code                 : translate("employeeCodeLabel"),
            last_name            : translate("employeeLastNameLabel"),
            first_name           : translate("employeeFirstNameLabel"),
            gender               : translate("employeeGenderLabel"),
            civil_status         : translate("employeeCivilStatusLabel"),
            id_number            : translate("employeeIdNumberLabel"),
            passport_number      : translate("employeePassportNumberLabel"),
            email                : translate("employeeEmailLabel"),
            phone                : translate("employeePhoneLabel"),
            address              : translate("employeeAddressLabel"),
            structure_type       : translate("employeeStructureTypeLabel"),
            structure_code       : translate("employeeStructureCodeLabel"),
            position_id          : translate("employeePositionIdLabel"),
            nationality          : translate("employeeNationalityLabel"),
            analytic_account_code: translate("employeeAnalyticAccountCodeLabel"),
            is_user              : translate("employeeIsUserLabel"),
            is_driver            : translate("employeeIsDriverLabel"),
            is_head            : translate("employeeIsHeadLabel"),
        };
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        const goPage = (page: number) => {
            pagination.value.page = page;

            getEmployees();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getEmployees();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getEmployees();
        };

        const create = () => {
            activeEmployee.value = null;
            const modal = new Modal(document.getElementById('kt_modal_edit_employee'));
            modal.show();
        };

        const edit = (supplier) => {
            activeEmployee.value = supplier;
            const modal = new Modal(document.getElementById('kt_modal_edit_employee'));
            modal.show();
        };

        const confirmRemove = (supplier_id: number) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("employeeConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(supplier_id);
                }
            });
        }

        const remove = (employee_id: number) => {

            axios.delete(`${ApiRoutes.employees.delete}/${employee_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });
                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const removeQueryParam = (name: string): void => {
            if (queryParams.value && typeof queryParams.value[name] !== 'undefined') {
                queryParams.value[name] = queryParamsDefault[name];
                if (name == 'structure_type') queryParams.value['structure_code'] = queryParamsDefault['structure_code'];

                refresh();
            }
        };

        const getEmployees = async () => {
            loading.value = true;

            const params = {};
            Object.assign(params, queryParams.value, pagination.value);

            await axios.get(ApiRoutes.employees.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    employees.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const searchEmployees = (params) => {
            Object.assign(queryParams.value, params);

            refresh();
        };

        onMounted(() => {
            getEmployees();

            setCurrentPageBreadcrumbs(translate("employeeManagementHeading"), [
                translate("masterDataHeading"),
                translate("referencialsHeading"),
                translate("employeesManagementHeading")
            ]);
        });

        return {
            translate,
            employees,
            queryParams,
            queryParamsDefault,
            queryParamsLabels,
            removeQueryParam,
            searchEmployees,
            pagination,
            activeEmployee,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
